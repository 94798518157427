<template>
    <div id="app" class="container p-3">

        <div class="row">

            <div class="col-md">
                <label class="form-label" for="code">Code einfügen:</label>
                <textarea v-model="code" id="code" class="form-control" placeholder="Füge hier deinen HTML-Code ein!"></textarea>
            </div>

            <div class="col-md mt-3 mt-md-0">
                <label class="form-label" for="file">Code hochladen:</label>
                <input @change="upload" class="form-control" type="file" id="file" ref="file">
            </div>

        </div>

        <div class="mt-3">
            <label class="form-label" for="code">HTML</label>
            <div v-if="html" v-html="html" class="border"></div>
            <div v-else class="alert alert-info">
                <p>Kopiere deinen Text oben in das Textfeld oder lade deinen Text direkt als Datei hoch.</p>
                <p class="mb-0">Das formatierte HTML wird dann in echtzeit hier angezeigt.</p>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'App',
    data() {
        return {
            code: '',
            html: '',
        }
    },
    watch: {
        code() {
            this.html = this.code;
        }
    },
    methods: {
        upload() {
            let _this = this,
                _fileInput = this.$refs.file;

            const reader = new FileReader();

            reader.addEventListener('load', function(){
                _this.html = reader.result;
                _fileInput.value = null;
            });

            reader.readAsText(_fileInput.files[0]);
        },
    }
}
</script>
